import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import AddrNotePrivacy from './pages/addrnote/privacy/provacy';
import AddrNoteTerms from './pages/addrnote/terms/terms';
import LedoPrivacy from './pages/ledo/privacy/provacy';
import LedoTerms from './pages/ledo/terms/terms';
import LehuiPrivacy from './pages/lehui/privacy/provacy';
import LehuiTerms from './pages/lehui/terms/terms';
import XiaohePrivacy from './pages/xiaohe/privacy/provacy';
import XiaoheTerms from './pages/xiaohe/terms/terms';
import HuiYuPrivacy from './pages/huiyu/privacy/provacy';
import HuiYuTerms from './pages/huiyu/terms/terms';
import AiWallpaperPrivacy from './pages/aiwallpaper/privacy/provacy';
import AiWallpaperTerms from './pages/aiwallpaper/terms/terms';
import DiaryPrivacy from './pages/diary/privacy/provacy';
import DiaryTerms from './pages/diary/terms/terms';
import UltraExtentPrivacy from './pages/ultraextent/privacy/privacy';
import UltraExtentTerms from './pages/ultraextent/terms/terms';


export default class Routers extends React.Component {
  render() {
  return (
    <BrowserRouter>
      {/* 使用 Routes 替换曾经的 Switch */}
      <Routes>
        <Route path='/' element={<App/>} />
        <Route path='addrnote/provacy' element={<AddrNotePrivacy/>} />
        <Route path='addrnote/terms' element={<AddrNoteTerms />} />
        <Route path='lehui/provacy' element={<LehuiPrivacy/>} />
        <Route path='lehui/terms' element={<LehuiTerms />} />
        <Route path='xiaohe/provacy' element={<XiaohePrivacy/>} />
        <Route path='xiaohe/terms' element={<XiaoheTerms />} />
        <Route path='ledo/provacy' element={<LedoPrivacy/>} />
        <Route path='ledo/terms' element={<LedoTerms/>} />
        <Route path='huiyu/provacy' element={<HuiYuPrivacy/>} />
        <Route path='huiyu/terms' element={<HuiYuTerms/>} />
        <Route path='aiwallpaper/provacy' element={<AiWallpaperPrivacy/>} />
        <Route path='aiwallpaper/terms' element={<AiWallpaperTerms/>} />
        <Route path='diary/provacy' element={<DiaryPrivacy/>} />
        <Route path='diary/terms' element={<DiaryTerms/>} />
        <Route path='ultraextent/privacy' element={<UltraExtentPrivacy/>} />
        <Route path='ultraextent/terms' element={<UltraExtentTerms/>} />
      </Routes>
    </BrowserRouter>
  );
  }
}